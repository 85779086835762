import { useEffect } from 'react';
import { OPTIMIZE_DOMAIN } from '#config';

const Optimize = () => {
  useEffect(() => {
    if (!OPTIMIZE_DOMAIN) return;
    const script = document.createElement('script');
    script.setAttribute('id', 'ab');
    script.setAttribute('strategy', 'afterInteractive');
    script.setAttribute('src', `${OPTIMIZE_DOMAIN}/public/ab.js`);
    document.body.appendChild(script);
  }, []);
  return '';
};

export default Optimize;
